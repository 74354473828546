import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route path="/">
                    <App/>
                </Route>
            </Switch>
        </Router>
    </React.StrictMode>
);